import { ConferenceScreenType } from "./ConferenceScreenType"

export interface Conference {
  uuid: string;
  name: string;
  operatorKeycode: string;
  hasActiveTrack: boolean;
  activeTrackUuid: string;
  activeTrackName: string;
  activeTrackScreen: ConferenceScreenType;
  activeQuestionUuid: string;
  twitterToken: string;
  twitterSecret: string;
  tweetPrefix: string;
  tweetSuffix: string;
}

export function isConference(arg: any): arg is Conference {
  return (
    arg.uuid !== undefined &&
    arg.name !== undefined &&
    arg.operatorKeycode !== undefined &&
    arg.hasActiveTrack !== undefined &&
    arg.activeTrackUuid !== undefined &&
    arg.activeTrackName !== undefined &&
    arg.activeTrackScreen !== undefined &&
    arg.activeQuestionUuid !== undefined
  );
}

export function isConferenceArray(arg: any): arg is Conference[] {
  return arg instanceof Array && arg.every(mayBeConference => isConference(mayBeConference));
}

export interface TweetTemplate {
  prefix: string;
  suffix: string;
}

export interface TwitterCredentials {
  token: string;
  secret: string;
}
