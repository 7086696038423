export interface Question {
  uuid: string;
  name: string;
  choices: string[];
  bothLanguageChoices: string[];
  results: number[];
  resultsRate: number[];
  showResultsValue: boolean;
}

export function isQuestion(arg: any): arg is Question {
  return (
    arg.uuid !== undefined &&
    arg.name !== undefined &&
    arg.choices !== undefined &&
    arg.bothLanguageChoices !== undefined &&
    arg.results !== undefined &&
    arg.resultsRate !== undefined &&
    arg.showResultsValue !== undefined
  );
}

export interface QuestionResultDatum {
  label: string;
  value: number;
  ratio: number;
}

export type QuestionResults = QuestionResultDatum[];
