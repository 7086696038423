import { useCallback, useState } from "react";

export function useClientRect() {
  const [rect, setRect] = useState<ClientRect | DOMRect | null>(null);
  const ref = useCallback(node => {
    function setClientRect() {
      if (node !== null && node instanceof HTMLElement) {
        setRect(node.getBoundingClientRect());
      }
    }
    setClientRect();
    window.addEventListener("resize", setClientRect);
    return () => window.removeEventListener("resize", setClientRect);
  }, []);
  return { rect, ref };
}
