import { Question, QuestionResults } from "../@type/Question";
export const questionToQuestionRestults = (
  question: Question
): QuestionResults => {
  return question.bothLanguageChoices.map((choice, index) => ({
    label: choice,
    value: question.results[index],
    ratio: question.resultsRate[index]
  }));
};
