export interface Comment {
  uuid: string;
  author: string;
  time: number;
  body: string;
  likesCount: number;
  liked: boolean;
  approve: boolean;
}

export function isComment(arg: any): arg is Comment {
  return (
    arg.uuid !== undefined &&
    arg.author !== undefined &&
    arg.time !== undefined &&
    arg.body !== undefined &&
    arg.likesCount !== undefined &&
    arg.liked !== undefined &&
    arg.approve !== undefined
  );
}

export function isCommentArray(arg: any): arg is Comment[] {
  return arg instanceof Array && arg.every(mayBeComment => isComment(mayBeComment));
}
