import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  Redirect
} from "react-router-dom";
import { Login } from "./screens/Login";
import { SelectConferences } from "./screens/SelectConferences";
import { Conferences } from "./screens/Conferences";
import { Live } from "./screens/Live";
import { AuthContainer } from "./context/auth";
// import { fakeAuthentication as auth } from "./Authentication";

const App: React.FC = () => {
  return (
    <AuthContainer.Provider initialState={localStorage.getItem("jwt") || ""}>
      <Router>
        <Switch>
          <Route path="/topics" component={Topics} />
          <Route path="/login" component={Login} />
          <Route path="/state/:conferenceGroupId" component={Live} />
          <PrivateRoute>
            <Route path="/select-conferences" component={SelectConferences} />
            <Route path="/conferences/:conferenceLiveGroupId" component={Conferences} />
            <Route path="/users">
              <Users />
            </Route>
          </PrivateRoute>
        </Switch>
      </Router>
      </AuthContainer.Provider>
  );
};

const PrivateRoute: React.FC = ({ children, ...rest }) => {
  const auth = AuthContainer.useContainer();
  return (
    <Route
      {...rest}
      render={() => {
        if (auth.authenticated()) {
          return children;
        }
        if (auth.notAuthenticated()) {
          return <Redirect to={{ pathname: "/login" }} />;
        }
        return <div />;
      }}
    ></Route>
  );
};

const Users: React.FC = () => <h2>Users</h2>;
const Topics: React.FC = () => {
  const match = useRouteMatch();

  return (
    <div>
      <h2>Topics</h2>
      {match && (
        <>
          <ul>
            <li>
              <Link to={`${match.url}/components`}>Components</Link>
            </li>
            <li>
              <Link to={`${match.url}/props-v-state`}>Props v. State</Link>
            </li>
          </ul>
          {/* The Topics page has its own <Switch> with more routes
          that build on the /topics URL path. You can think of the
          2nd <Route> here as an "index" page for all topics, or
          the page that is shown when no topic is selected */}
          <Switch>
            <Route path={`${match.path}/:topicId`}>
              <Topic />
            </Route>
            <Route path={match.path}>
              <h3>Please select a topic.</h3>
            </Route>
          </Switch>
        </>
      )}
    </div>
  );
};

const Topic: React.FC = () => {
  let { topicId } = useParams();
  return <h3>Requested topic ID: {topicId}</h3>;
};

export default App;
