import React, { useState } from "react";
import { Modal, Input, Button, Result } from "antd";
import html2canvas from "html2canvas";
import { AuthContainer } from "../../context/auth";
import { authoricationPostHeader } from "../../helpers/FetchHelper";
import { TweetTemplate } from "../../@type/Conference";

export enum TweetType {
  comment,
  question
}
interface TweetModalProps {
  visible: boolean;
  defaultText: string;
  uuid: string;
  onCancel: () => void;
  tweetTemplate: TweetTemplate;
  tweetType: TweetType;
  captureRef?: React.RefObject<HTMLDivElement>;
}
enum TweetResult {
  idle,
  processing,
  success,
  errror
}

const postTweet = async (
  type: TweetType,
  authToken: string,
  tweet: string,
  uuid: string,
  captureRef?: React.RefObject<HTMLDivElement>
) => {
  if (type === TweetType.question) {
    const canvas = await html2canvas(captureRef?.current || document.body);
    const blob = await new Promise<Blob | null>(resolve =>
      canvas.toBlob(blobed => resolve(blobed), "image/jpeg")
    );
    const image = canvas.toDataURL("image/jpeg", 1.0);
    if (!blob) {
      throw new Error("No Blob Exception!");
    }
    // const reader = new FileReader();
    // const data = await new Promise<string | ArrayBuffer | null>(resolve => {
    //   reader.onload = event => resolve(event.target?.result);
    //   reader.readAsDataURL(blob);
    // });
    const formData = new FormData();
    formData.append("file", image);
    // formData.append("file", new Blob([blob], { type: "image/jpeg" }));
    // formData.append("file", new Blob(['ancsadf'], { type: "test/plain" }));
    formData.append("tweet", tweet);
    return await fetch(`/api/v2/questions/${uuid}/tweets`, {
      headers: authoricationPostHeader(authToken),
      method: "POST",
      body: JSON.stringify({
        tweet,
        image
      })
    });
    // document.body.appendChild(canvas);
  }
  return await fetch(`/api/v2/comments/${uuid}/tweets`, {
    headers: authoricationPostHeader(authToken),
    method: "POST",
    body: JSON.stringify({ tweet })
  });
};
export const TweetModal: React.FC<TweetModalProps> = props => {
  const [tweet, setTweet] = useState(
    `${props.tweetTemplate.prefix}${props.defaultText}${props.tweetTemplate.suffix}`
  );
  const [tweetResult, setTweetResult] = useState<TweetResult>(TweetResult.idle);
  const auth = AuthContainer.useContainer();
  return (
    <Modal
      title="twitterに共有します"
      visible={props.visible}
      cancelText="キャンセル"
      okText={okText(tweetResult)}
      onCancel={() => {
        setTweetResult(TweetResult.idle);
        props.onCancel();
      }}
      okButtonProps={
        tweetResult === TweetResult.processing ? { disabled: true } : {}
      }
      footer={
        <Footer
          tweetResult={tweetResult}
          onCancel={() => {
            setTweetResult(TweetResult.idle);
            props.onCancel();
          }}
          onOk={async () => {
            setTweetResult(TweetResult.processing);
            try {
              const result = await postTweet(
                props.tweetType,
                auth.authToken,
                tweet,
                props.uuid,
                props.captureRef
              );
              if (result.ok) {
                setTweetResult(TweetResult.success);
              }
            } catch (err) {
              console.error(err);
            }
          }}
        />
      }
    >
      {(tweetResult === TweetResult.idle ||
        tweetResult === TweetResult.processing) && (
        <Input.TextArea
          rows={4}
          value={tweet}
          onChange={event => setTweet(event.target.value)}
        />
      )}
      {tweetResult === TweetResult.success && (
        <Result status="success" title="twitterへの共有が完了しました！" />
      )}
    </Modal>
  );
};

interface FooterProps {
  tweetResult: TweetResult;
  onOk: () => void;
  onCancel: () => void;
}
const Footer: React.FC<FooterProps> = props => {
  if (
    props.tweetResult === TweetResult.idle ||
    props.tweetResult === TweetResult.processing
  ) {
    return (
      <>
        <Button key="back" onClick={() => props.onCancel()}>
          閉じる
        </Button>
        <Button
          key="submit"
          type="primary"
          loading={props.tweetResult === TweetResult.processing}
          onClick={() => props.onOk()}
        >
          {props.tweetResult === TweetResult.processing ? "共有中" : "共有する"}
        </Button>
      </>
    );
  }
  return (
    <Button key="back" onClick={() => props.onCancel()}>
      閉じる
    </Button>
  );
};

const okText = (tweetResult: TweetResult): string => {
  if (tweetResult === TweetResult.idle) {
    return "共有する";
  }
  if (tweetResult === TweetResult.processing) {
    return "共有中";
  }
  if (tweetResult === TweetResult.success) {
    return "閉じる";
  }
  return "共有する";
};
