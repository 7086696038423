import React, { useState } from "react";
import style from "./Schedule.module.scss";
import { Dropdown, Menu, Popconfirm } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { Session, LiveSession, SessionKind } from "../Timetable";
import { postHeader } from "../../helpers/FetchHelper";
interface ActionProps extends Session {
  onKindChange: (kind: SessionKind) => void;
}
export const Action: React.FC<ActionProps> = props => {
  const [showMenu, setShowMenu] = useState(false);
  const liveSession = LiveSession.useContainer();
  const [approvingComments, setApprovingComments] = useState(
    props.approvingComments
  );
  return (
    <div>
      <Dropdown
        visible={showMenu}
        onVisibleChange={flag => setShowMenu(flag)}
        key="option"
        placement="bottomRight"
        className={style.scheduleOption}
        trigger={["click"]}
        overlay={
          <Menu>
            <Menu.Item key={`ScheduleActionMenu${props.uuid}`}>
              {props.live ? (
                <Popconfirm
                  title="セッションを終了してよろしいですか？"
                  onConfirm={() => {
                    liveSession.end();
                    setShowMenu(false);
                  }}
                  okText="終了"
                  onCancel={() => setShowMenu(false)}
                  cancelText="キャンセル"
                  placement="left"
                >
                  <div>終了する</div>
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="他のセッションを終了して、このセッションを開始します。"
                  onConfirm={() => {
                    liveSession.start(props.uuid);
                    setShowMenu(false);
                  }}
                  okText="開始"
                  onCancel={() => {
                    setShowMenu(false);
                  }}
                  cancelText="キャンセル"
                  placement="left"
                >
                  <div>開始する（ONとなります）</div>
                </Popconfirm>
              )}
            </Menu.Item>
            {props.kind === SessionKind.KAIGI100 && (
              <Menu.Item disabled={props.live}>
                <Popconfirm
                  title="稼働モードを質問箱に変更してよろしいですか？"
                  onConfirm={() => {
                    fetch(`/api/v2/tracks/${props.uuid}/kind`, {
                      headers: postHeader(),
                      method: "PATCH",
                      body: JSON.stringify({
                        kind: SessionKind.QUESTION_BOX
                      })
                    });
                    setShowMenu(false);
                    props.onKindChange(SessionKind.QUESTION_BOX);
                  }}
                  okText="OK"
                  onCancel={() => {
                    setShowMenu(false);
                  }}
                  cancelText="キャンセル"
                  placement="left"
                  disabled={props.live}
                >
                  {props.live ? (
                    "稼働モードを質問箱に変更する"
                  ) : (
                    <div>稼働モードを質問箱に変更する</div>
                  )}
                </Popconfirm>
              </Menu.Item>
            )}
            {props.kind === SessionKind.QUESTION_BOX && (
              <Menu.Item disabled={props.live}>
                <Popconfirm
                  title="稼働モードを百人会議に変更してよろしいですか？"
                  onConfirm={() => {
                    fetch(`/api/v2/tracks/${props.uuid}/kind`, {
                      headers: postHeader(),
                      method: "PATCH",
                      body: JSON.stringify({
                        kind: SessionKind.KAIGI100
                      })
                    });
                    setShowMenu(false);
                    props.onKindChange(SessionKind.KAIGI100);
                  }}
                  okText="OK"
                  onCancel={() => {
                    setShowMenu(false);
                  }}
                  cancelText="キャンセル"
                  placement="left"
                  disabled={props.live}
                >
                  {props.live ? (
                    "稼働モードを百人会議に変更する"
                  ) : (
                    <div>稼働モードを百人会議に変更する</div>
                  )}
                </Popconfirm>
              </Menu.Item>
            )}
            {approvingComments ? (
              <Menu.Item>
                <Popconfirm
                  title="コメントを自動承認にしてよろしいですか？"
                  onConfirm={() => {
                    fetch(
                      `/api/v2/conferences/${props.conferenceUuid}/approving_comments`,
                      {
                        headers: postHeader(),
                        method: "DELETE"
                      }
                    );
                    setApprovingComments(false);
                    setShowMenu(false);
                  }}
                  okText="OK"
                  onCancel={() => {
                    setShowMenu(false);
                  }}
                  cancelText="キャンセル"
                  placement="left"
                >
                  <div>コメントを自動承認にする</div>
                </Popconfirm>
              </Menu.Item>
            ) : (
              <Menu.Item>
                <Popconfirm
                  title="コメントを承認制にしてよろしいですか？"
                  onConfirm={() => {
                    fetch(
                      `/api/v2/conferences/${props.conferenceUuid}/approving_comments`,
                      {
                        headers: postHeader(),
                        method: "POST"
                      }
                    );
                    setApprovingComments(true);
                    setShowMenu(false);
                  }}
                  okText="OK"
                  onCancel={() => {
                    setShowMenu(false);
                  }}
                  cancelText="キャンセル"
                  placement="left"
                >
                  <div>コメントを承認制にする</div>
                </Popconfirm>
              </Menu.Item>
            )}
          </Menu>
        }
      >
        <div className={style.scheduleOptionContainer}>
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
      </Dropdown>
    </div>
  );
};
