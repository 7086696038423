import React, { useState } from "react";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Tag } from "antd";
import { TweetTemplate, TwitterCredentials } from "../../@type/Conference";
import { TweetModal, TweetType } from "./TweetModal";
import style from "./TweetButton.module.scss";

interface TweetButtonProps {
  tweetType: TweetType;
  tweetTemplate: TweetTemplate;
  twitterCredentials: TwitterCredentials;
  body: string;
  uuid: string;
  captureRef?: React.RefObject<HTMLDivElement>;
  compact?: boolean;
}

export const TweetButton: React.FC<TweetButtonProps> = props => {
  const [showTweetModal, setShowTweetModal] = useState(false);
  if (!props.twitterCredentials.token || !props.twitterCredentials.secret) {
    return <></>;
  }
  const className = props.compact ? style.tag : "";
  return (
    <>
      <Tag
        color="blue"
        className={className}
        onClick={() => setShowTweetModal(true)}
      >
        <FontAwesomeIcon icon={(faTwitter as unknown) as IconProp} />
        ツイート
      </Tag>
      <TweetModal
        visible={showTweetModal}
        defaultText={props.body}
        uuid={props.uuid}
        onCancel={() => setShowTweetModal(false)}
        tweetTemplate={props.tweetTemplate}
        tweetType={props.tweetType}
        captureRef={props.captureRef}
      />
    </>
  );
};

TweetButton.defaultProps = {
  compact: true
};
