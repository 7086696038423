import React from "react";
import { Bar } from "./chart/Bar";
import { questionToQuestionRestults } from "../helpers/QuestionHelper";
import { useClientRect } from "../hooks/Rect";
import { Question } from "../@type/Question";

const defaultColors = [
  "#6a9ae2",
  "#f25a5a",
  "#1de212",
  "#ba12e2",
  "#12d7e2",
  "#ebeb0a",
  "#eba80a",
  "#e2b5b5",
  "#f7b6f1",
  "#d2d2d2",
  "#7fffd4",
  "#deb887"
];

export const Chart: React.FC<Question> = props => {
  const { rect, ref } = useClientRect();

  if (rect === null) {
    return <div className="chart" ref={ref} style={{ width: "100%" }} />;
  }

  const questionResults = questionToQuestionRestults(props);
  const maxValue = questionResults.reduce((a, b) => (a.value > b.value ? a : b))
    .value;
  const maxCharacters = questionResults.reduce((a, b) =>
    a.label.length > b.label.length ? a : b
  ).label.length;
  let datumSize = rect.height / questionResults.length;
  // if (datumSize < 50) {
  //   datumSize = rect.height / Math.ceil(questionResults.length / 2);
  // }
  if (datumSize > 150) {
    datumSize = 150;
  }
  let labelSize = 0.5;
  const oneLineCharacters = Math.ceil(rect.width / (datumSize * labelSize)) - 3;
  if (oneLineCharacters < maxCharacters) {
    labelSize = labelSize * (oneLineCharacters / maxCharacters);
  }
  return (
    <div className="chart" ref={ref} style={{ width: "100%" }}>
      {questionResults.map((datum, index) => (
        <Bar
          key={`chart-bar-${index}`}
          label={datum.label}
          value={datum.value}
          ratio={datum.ratio}
          width={`calc(${(datum.value / maxValue) * 100}% - 100px)`}
          fillColor={defaultColors[index]}
          baseSize={`${datumSize}px`}
          labelSize={`${labelSize}em`}
          showValue={props.showResultsValue}
        />
      ))}
    </div>
  );
};
