const setAuthorizationHeader = (headers: Headers, token: string) => {
  headers.append("Authorization", `Bearer: ${token}`);
  return headers;
};

export const authorizationHeader = (token: string) => {
  const headers = new Headers();
  return setAuthorizationHeader(headers, token);
};

export const postHeader = () => {
 return new Headers({ "Content-Type": "application/json"}); 
}

export const authoricationPostHeader = (token: string) => {
  const headers = postHeader();
  return setAuthorizationHeader(headers, token);
};
