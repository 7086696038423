import React, { useState } from "react";
import { Card, Typography, Form, Icon, Input, Button, Alert } from "antd";
import style from "./Login.module.scss";
import { FormComponentProps } from "antd/lib/form/Form";
import { useHistory } from "react-router";
import { AuthContainer } from "../context/auth";

export const Login: React.FC = () => (
  <div className={style.layout}>
    <Card>
      <Typography>
        {/* <Typography.Title>JSCPT40</Typography.Title> */}
        <Typography.Title level={3}>百人会議 集中管理パネル</Typography.Title>
        <Typography.Paragraph>
          ログインIDとパスワードを入力してください。
        </Typography.Paragraph>
      </Typography>
      <LoginForm />
    </Card>
  </div>
);

interface LoginFormProps extends FormComponentProps {
  loginId: string;
  password: string;
}

const LoginForm = Form.create({ name: "Login" })((props: LoginFormProps) => {
  const {
    getFieldDecorator,
    getFieldsError,
    validateFields,
    setFields
  } = props.form;
  const [signingIn, setSigningIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const auth = AuthContainer.useContainer();
  return (
    <>
      {errorMessage && <Alert message={errorMessage} type="error" />}
      <Form
        onSubmit={event => {
          setSigningIn(true);
          event.preventDefault();
          validateFields(async (err, values: LoginFormProps) => {
            if (err) {
              console.log(getFieldsError());
              setSigningIn(false);
              return;
            }
            const response = await auth.authentication(values.loginId, values.password);
            console.log("Received values of form: ", response);
            if (response.status === 401) {
              setSigningIn(false);
              setFields({
                loginId: { value: values.loginId, errors: [] }
              });
              setFields({
                password: { value: values.password, errors: [] }
              });
              setErrorMessage("ログインIDか、パスワードが違います。");
              return;
            }
            history.replace("/select-conferences");
          });
        }}
      >
        <Form.Item label="ログインID">
          {getFieldDecorator("loginId", {
            rules: [{ required: true, message: "ログインIDを入力してください" }]
          })(
            <Input
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="ログインID"
            />
          )}
        </Form.Item>
        <Form.Item label="パスワード">
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "パスワードを入力してください" }]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="パスワード"
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={signingIn}>
            ログイン
          </Button>
        </Form.Item>
      </Form>
    </>
  );
});
