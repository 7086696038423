import { useState, useEffect, useMemo } from "react";
import { FetchStatus } from "../@type/FetchStatus";
import { AuthContainer } from "../context/auth";

const buildRequestInit = (init: RequestInit, authToken: string) => {
  if (!init.headers) {
    return Object.assign({}, init, {
      headers: { Authorization: `Bearer: ${authToken}` }
    });
  }
  const headers = Object.assign({}, init.headers, {
    Authorization: `Bearer: ${authToken}`
  });
  return Object.assign({}, init, { headers });
};

interface FetchHookOption {
  init?: RequestInit;
  autoReload?: boolean;
  interval?: number;
}

export const useFetch = (input: RequestInfo, option?: FetchHookOption) => {
  const [, setFetchStatus] = useState<FetchStatus>(FetchStatus.fetching);
  const [primaryFetching, setPrimaryFetching] = useState(true);
  const [primaryFetchFulfilled, setPrimaryFetchFulfilled] = useState(false);
  const [updateFetching, setUpdateFetching] = useState(false);
  const [updateFetchFulfilled, setUpdateFetchFulfilled] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [fetchFulfilled, setFetchfulfilled] = useState(false);
  const [response, setResponse] = useState<Response>();
  const [responseJson, setResponseJson] = useState({});
  const auth = AuthContainer.useContainer();
  const fetchOption: FetchHookOption = useMemo(
    () =>
      Object.assign(
        {},
        { init: {}, autoReload: false, interval: 5000 },
        option
      ),
    // optionのデフォルト値設定、optionをdepsに入れるとuseEffectが無限ループするので、depsをなしにする
    // https://github.com/facebook/react/issues/14476#issuecomment-471199055
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    let timeoutId = 0;
    let cleanup = false;
    async function request(primary: boolean = true) {
      if (!input) {
        return;
      }

      const response = await fetch(
        input,
        buildRequestInit(fetchOption.init || {}, auth.authToken)
      )
      if (cleanup) {
        return;
      }
      setFetching(false);
      if (primary) {
        setPrimaryFetching(false);
      } else {
        setUpdateFetching(false);
      }
      if (response.status === 401) {
        auth.signOut();
      }
      if (response.status !== 200) {
        setFetchStatus(FetchStatus.error);
        return;
      }
      const json = await response.json();
      setResponse(response);
      setResponseJson(json);
      setFetchStatus(FetchStatus.fulfilled);
      setFetchfulfilled(true);
      if (primary) {
        setPrimaryFetchFulfilled(true);
      } else {
        setUpdateFetchFulfilled(true);
      }
      if (fetchOption.autoReload) {
        timeoutId = setTimeout(() => {
          setFetching(true);
          setUpdateFetching(true);
          request(false);
        }, fetchOption.interval);
      }
    }
    request();
    return () => {
      clearTimeout(timeoutId);
      cleanup = true;
    };
  }, [input, auth, fetchOption]);
  return {
    response,
    responseJson,
    fetching,
    fetchFulfilled,
    primaryFetching,
    primaryFetchFulfilled,
    updateFetching,
    updateFetchFulfilled
  };
};

// TODO: useFetchと共通化して、authをオプションにする
export const useFetchNoAuth = (input: RequestInfo, option?: FetchHookOption) => {
  const [, setFetchStatus] = useState<FetchStatus>(FetchStatus.fetching);
  const [primaryFetching, setPrimaryFetching] = useState(true);
  const [primaryFetchFulfilled, setPrimaryFetchFulfilled] = useState(false);
  const [updateFetching, setUpdateFetching] = useState(false);
  const [updateFetchFulfilled, setUpdateFetchFulfilled] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [fetchFulfilled, setFetchfulfilled] = useState(false);
  const [response, setResponse] = useState<Response>();
  const [responseJson, setResponseJson] = useState({});
  const fetchOption: FetchHookOption = useMemo(
    () =>
      Object.assign(
        {},
        { init: {}, autoReload: false, interval: 5000 },
        option
      ),
    // optionのデフォルト値設定、optionをdepsに入れるとuseEffectが無限ループするので、depsをなしにする
    // https://github.com/facebook/react/issues/14476#issuecomment-471199055
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    let timeoutId = 0;
    let cleanup = false;
    setPrimaryFetching(true);
    setPrimaryFetchFulfilled(false);
    async function request(primary: boolean = true) {
      if (!input) {
        return;
      }

      const response = await fetch(
        input,
        fetchOption.init || {}
      )
      if (cleanup) {
        return;
      }
      setFetching(false);
      if (primary) {
        setPrimaryFetching(false);
      } else {
        setUpdateFetching(false);
      }
      if (response.status !== 200) {
        setFetchStatus(FetchStatus.error);
        return;
      }
      const json = await response.json();
      setResponse(response);
      setResponseJson(json);
      setFetchStatus(FetchStatus.fulfilled);
      setFetchfulfilled(true);
      if (primary) {
        setPrimaryFetchFulfilled(true);
      } else {
        setUpdateFetchFulfilled(true);
      }
      if (fetchOption.autoReload) {
        timeoutId = setTimeout(() => {
          setFetching(true);
          setUpdateFetching(true);
          request(false);
        }, fetchOption.interval);
      }
    }
    request();
    return () => {
      clearTimeout(timeoutId);
      cleanup = true;
    };
  }, [input, fetchOption]);
  return {
    response,
    responseJson,
    fetching,
    fetchFulfilled,
    primaryFetching,
    primaryFetchFulfilled,
    updateFetching,
    updateFetchFulfilled
  };
};
