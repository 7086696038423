import React, { useState, useRef } from "react";
import style from "./Conferences.module.scss";
import { Button, Drawer, Empty, Typography, Dropdown, Menu, Tag} from "antd";
import { CommentItem } from "../components/Comment";
import { Chart } from "../components/Chart";
import {
  Conference,
  isConferenceArray,
  TweetTemplate,
  TwitterCredentials
} from "../@type/Conference";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faExternalLink } from "@fortawesome/pro-light-svg-icons";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { useFetch } from "../hooks/Fetch";
import { ConferenceScreenType } from "../@type/ConferenceScreenType";
import { isCommentArray } from "../@type/Comment";
import { isQuestion } from "../@type/Question";
import { AuthContainer } from "../context/auth";
import { TweetButton } from "../components/twitter/TweetButton";
import { TweetType } from "../components/twitter/TweetModal";

const perRow = (conferencesCount: number) => {
  if (conferencesCount <= 4) {
    return conferencesCount;
  } else if (conferencesCount <= 6) {
    return 3;
  } else if (conferencesCount <= 8) {
    return 4;
  } else if (conferencesCount <= 9) {
    return 3;
  } else if (conferencesCount <= 12) {
    return 4;
  }
  return 3;
};

export const Conferences: React.FC = () => {
  const params = useParams<{ conferenceLiveGroupId: string }>();
  const [drawer, setDrawer] = useState(false);
  const { signOut } = AuthContainer.useContainer();
  const {
    primaryFetching,
    primaryFetchFulfilled,
    responseJson
  } = useFetch(
    `/api/v2/conference_live_groups/${params.conferenceLiveGroupId}`,
    { autoReload: true }
  );
  return (
    <div
      className={`${style.conferencesContainer} ${isConferenceArray(
        responseJson
      ) && style[`item${responseJson.length}`]}`}
    >
      {primaryFetching && <span>読み込み中...</span>}
      {primaryFetchFulfilled &&
        isConferenceArray(responseJson) &&
        responseJson.map(conference => (
          <ConferenceItem
            key={`${conference.uuid}-${conference.activeTrackUuid}`}
            {...conference}
            style={{ height: `${100 / perRow(responseJson.length)}vh` }}
          />
        ))}
      <div>
        <div className={style.drawerButtonWrapper}>
          <Button type="primary" onClick={() => setDrawer(true)}>
            <FontAwesomeIcon icon={faBars} />
          </Button>
        </div>
        <Drawer
          title="操作メニュー"
          placement="right"
          onClose={() => setDrawer(false)}
          visible={drawer}
        >
          <a
            href="logout"
            onClick={event => {
              event.preventDefault();
              signOut();
            }}
          >
            ログアウト
          </a>
        </Drawer>
      </div>
    </div>
  );
};

const ScreenIndicator: React.FC<{
  conferenceScreenType: ConferenceScreenType;
}> = ({ conferenceScreenType }) => {
  if (conferenceScreenType === ConferenceScreenType.ANSWERING_QUESTION) {
    return <div>回答受付中</div>;
  }
  if (conferenceScreenType === ConferenceScreenType.COMMENTS) {
    return <div>新着コメント</div>;
  }
  if (
    conferenceScreenType === ConferenceScreenType.RESULTS_ANNOUNCED_QUESTION
  ) {
    return <div>回答公開中</div>;
  }
  return <div>オフライン</div>;
};
const menu = (conference: Conference) => (
  <Menu>
    <Menu.Item key="0">
      <a
        href={`https://www.kaigi100.com/pc?ok=${conference.operatorKeycode}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span style={{ marginRight: 4 }}>PC画面を表示</span>
        <FontAwesomeIcon icon={faExternalLink} />
      </a>
    </Menu.Item>
  </Menu>
);

interface ConferenceItemProps extends Conference {
  style: React.CSSProperties;
}
const ConferenceItem: React.FC<ConferenceItemProps> = props => (
  <div
    className={style.conferenceContainer}
    key={props.uuid}
    style={props.style}
  >
    <div
      className={`${style.conferenceHeader} ${
        style[`${props.hasActiveTrack ? "active" : "offline"}`]
      }`}
    >
      <div className={style.title}>
        <div className={style.ellipsis}>
          {props.name}
          {props.hasActiveTrack && `/${props.activeTrackName}`}
        </div>
      </div>
      <Dropdown
        overlay={menu(props)}
        trigger={["click"]}
        className={style.options}
      >
        <div>
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
      </Dropdown>
      <div className={`${style.indicator} ${style[props.activeTrackScreen]}`}>
        <ScreenIndicator conferenceScreenType={props.activeTrackScreen} />
      </div>
    </div>
    <div className={style.conferenceContent}>
      {props.activeTrackScreen === ConferenceScreenType.COMMENTS && (
        <CommentCollection
          trackUuid={props.activeTrackUuid}
          tweetTemplate={{
            prefix: props.tweetPrefix,
            suffix: props.tweetSuffix
          }}
          twitterCredentials={{
            token: props.twitterToken,
            secret: props.twitterSecret
          }}
        />
      )}
      {props.activeTrackScreen === ConferenceScreenType.ANSWERING_QUESTION && (
        <CommentCollection
          trackUuid={props.activeTrackUuid}
          tweetTemplate={{
            prefix: props.tweetPrefix,
            suffix: props.tweetSuffix
          }}
          twitterCredentials={{
            token: props.twitterToken,
            secret: props.twitterSecret
          }}
        />
      )}
      {props.activeTrackScreen ===
        ConferenceScreenType.RESULTS_ANNOUNCED_QUESTION && (
        <ChartContainer
          questionUuid={props.activeQuestionUuid}
          tweetTemplate={{
            prefix: props.tweetPrefix,
            suffix: props.tweetSuffix
          }}
          twitterCredentials={{
            token: props.twitterToken,
            secret: props.twitterSecret
          }}
        />
      )}
      {!props.hasActiveTrack && (
        <Empty
          image={<div />}
          description={<span>アクティブなセッションがありません</span>}
          style={{ marginTop: 60 }}
        />
      )}
    </div>
  </div>
);

const CommentCollection: React.FC<{
  trackUuid: string;
  tweetTemplate: TweetTemplate;
  twitterCredentials: TwitterCredentials;
}> = ({ trackUuid, tweetTemplate, twitterCredentials }) => {
  const {
    primaryFetching,
    primaryFetchFulfilled,
    responseJson
  } = useFetch(`/api/v2/tracks/${trackUuid}/comments`, { autoReload: true });
  return (
    <>
      {primaryFetching && <span>読み込み中</span>}
      {primaryFetchFulfilled &&
        isCommentArray(responseJson) &&
        responseJson.map(comment => (
          <CommentItem
            key={comment.uuid}
            {...comment}
            tweetTemplate={tweetTemplate}
            twitterCredentials={twitterCredentials}
          />
        ))}
      {primaryFetchFulfilled &&
        isCommentArray(responseJson) &&
        responseJson.length === 0 && (
          <Empty
            description={<span>まだコメントがありません</span>}
            style={{ marginTop: 20 }}
          />
        )}
    </>
  );
};

interface ChartContainerProps {
  questionUuid: string;
  tweetTemplate: TweetTemplate;
  twitterCredentials: TwitterCredentials;
}
const ChartContainer: React.FC<ChartContainerProps> = ({
  questionUuid,
  tweetTemplate,
  twitterCredentials
}) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const {
    primaryFetching,
    primaryFetchFulfilled,
    responseJson: question
  } = useFetch(`/api/v2/questions/${questionUuid}`, { autoReload: true });
  if (primaryFetching) {
    return <div>読み込み中</div>;
  }
  if (primaryFetchFulfilled && isQuestion(question)) {
    return (
      <div className={style.chartContainer}>
        <div className={style.chartContent} ref={chartRef}>
          <Typography>
            <Typography.Title level={3}>{question.name}</Typography.Title>
          </Typography>
          <Chart {...question} />
        </div>
        <div className={style.tweetButtonContainer}>
          <Tag color="green">ピン留めする</Tag>
          <TweetButton
            tweetTemplate={tweetTemplate}
            twitterCredentials={twitterCredentials}
            body={question.name}
            tweetType={TweetType.question}
            captureRef={chartRef}
            uuid={questionUuid}
            compact={false}
          />
        </div>
      </div>
    );
  }
  return <div>Type Error{JSON.stringify(question)}</div>;
};
