import React, { useState } from "react";
import style from "./SelectConferences.module.scss";
import { Card, Typography, Form, Checkbox, Button, Alert } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import { useHistory } from "react-router-dom";
import { useFetch } from "../hooks/Fetch";
import { isConferenceLiveGroup } from "../@type/ConferenceLiveGroup";
import { AuthContainer } from "../context/auth";
import { authoricationPostHeader } from "../helpers/FetchHelper";

interface Conference {
  name: string;
  id: number;
}
interface SelectSessionsProps {
  conferences: Conference[];
}
export const SelectConferences: React.FC<SelectSessionsProps> = () => {
  const { responseJson, fetching, fetchFulfilled } = useFetch(
    "/api/v2/conferences"
  );

  return (
    <div className={style.layout}>
      <Card style={{ maxHeight: "80vh", overflow: "scroll" }}>
        <Typography>
          <Typography.Title level={3}>百人会議 集中管理パネル</Typography.Title>
          <Typography.Paragraph>
            表示する会場を選択してください
          </Typography.Paragraph>
        </Typography>
        {fetching && <div>読み込み中....</div>}
        {fetchFulfilled && (
          <SelectSessionsForm conferences={responseJson as Conference[]} />
        )}
      </Card>
    </div>
  );
};

interface SelectConferencesFormProps extends FormComponentProps {
  conferences: Conference[];
}

const SelectSessionsForm = Form.create<SelectConferencesFormProps>({
  name: "SelectSessions"
})((props: SelectConferencesFormProps) => {
  const { getFieldDecorator, getFieldValue } = props.form;
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const auth = AuthContainer.useContainer();
  return (
    <>
      {errorMessage && <Alert message={errorMessage} type="error" />}
      <Form
        onSubmit={async event => {
          event.preventDefault();
          setSubmitting(true);
          const response = await fetch("/api/v2/conference_live_groups", {
            headers: authoricationPostHeader(auth.authToken),
            method: "POST",
            body: JSON.stringify({ conferences: getFieldValue("conferences") })
          });
          if (response.status === 401) {
            auth.signOut();
          }
          setSubmitting(false);
          if (!response.ok) {
            setErrorMessage(
              "エラーが発生しました。もう一度ボタンを押してください。何度もエラーが発生する場合は、画面を更新して再度ボタンを押してください。"
            );
            return;
          }
          const conferenceLiveGroup = await response.json();
          if (!isConferenceLiveGroup(conferenceLiveGroup)) {
            setErrorMessage(
              "エラーが発生しました。もう一度ボタンを押してください。何度もエラーが発生する場合は、画面を更新して再度ボタンを押してください。"
            );
            return;
          }
          history.push(`/conferences/${conferenceLiveGroup.friendlyId}`);
        }}
      >
        <Form.Item>
          {getFieldDecorator("conferences", {
            initialValue: []
          })(
            <Checkbox.Group>
              {props.conferences.map(conference => (
                <React.Fragment
                  key={`SelectConferences-Conference-${conference.id}`}
                >
                  <Checkbox value={conference.id}>{conference.name}</Checkbox>
                  <br />
                </React.Fragment>
              ))}
            </Checkbox.Group>
          )}
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          loading={submitting}
          disabled={getFieldValue("conferences").length < 1}
        >
          選択した会場を表示
        </Button>
      </Form>
    </>
  );
});
