import React, { useState } from "react";
import { Button } from "antd";
import style from "./Timetable.module.scss";
import { useFetchNoAuth as useFetch } from "../hooks/Fetch";
import { Schedule } from "./timetable/Schedule";
import { createContainer } from "unstated-next";
import { postHeader } from "../helpers/FetchHelper";

const HourSign: React.FC<{ hour: number }> = ({ hour }) => (
  <div className={style.hourSign}>
    <div className={style.hourSignLine}></div>
    <div className={style.hourSignText}>{hour}:00</div>
  </div>
);

export enum SessionKind {
  KAIGI100 = "kaigi100",
  QUESTION_BOX = "question_box"
}
export interface Session {
  uuid: string;
  conferenceUuid: string;
  name: string;
  kind: SessionKind;
  begin: number;
  end: number;
  live: boolean;
  approvingComments: boolean;
}
function isSessions(arg: any): arg is Session[] {
  return (
    arg instanceof Array &&
    arg.every(
      mayBeSession =>
        mayBeSession.uuid !== undefined &&
        mayBeSession.conferenceUuid !== undefined &&
        mayBeSession.name !== undefined &&
        mayBeSession.kind !== undefined &&
        mayBeSession.begin !== undefined &&
        mayBeSession.end !== undefined &&
        mayBeSession.live !== undefined &&
        mayBeSession.approvingComments !== undefined
    )
  );
}

interface TimetableProps {
  conferenceUuid: string;
  showDate: string;
}
export const Timetable: React.FC<TimetableProps> = ({
  conferenceUuid,
  showDate
}) => {
  const {
    primaryFetchFulfilled,
    responseJson: sessions
  } = useFetch(
    `/api/v2/conferences/${conferenceUuid}/tracks/?date=${showDate}`, { autoReload: true }
  );
  return (
    <div className={style.timetableContainer}>
      <div className={style.timetableSchedule}>
        <div className={style.timetable}>
          <div className={style.timeTableSign}>
            {[7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21].map(hour => (
              <HourSign hour={hour} key={`HourSign-${hour}`} />
            ))}
          </div>
          {primaryFetchFulfilled && isSessions(sessions) && (
            <LiveSession.Provider
              initialState={{
                liveSessionUuid: findActiveSessionUuid(sessions),
                conferenceUuid: conferenceUuid
              }}
            >
              {sessions.map(session => (
                <Schedule key={session.uuid} {...session} />
              ))}
            </LiveSession.Provider>
          )}
        </div>
      </div>
      <div className={style.timetableActions}>
        <a href={`https://www.kaigi100.com/admin/tracks/new?conference=${conferenceUuid}&returnLink=state`} target="_parent">
          <Button>+</Button>
        </a>
      </div>
    </div>
  );
};

// work around optional chaining for https://github.com/facebook/create-react-app/issues/7431
function findActiveSessionUuid(sessions: Session[]) {
  const activeSession = sessions.find(session => session.live);
  if (activeSession) {
    return activeSession.uuid;
  }
  return "";
}
interface LiveSessionContainerProps {
  liveSessionUuid: string;
  conferenceUuid: string;
}
function useLiveSession(initialState: LiveSessionContainerProps | undefined) {
  let [liveSessionUuid, setLiveSessionUuid] = useState(
    initialState && initialState.liveSessionUuid
  );
  let start = (liveSessionUuid: string) => {
    setLiveSessionUuid(liveSessionUuid);
    fetch(`/api/v2/tracks/${liveSessionUuid}/live`, {
      headers: postHeader(),
      method: "POST"
    });
  };
  let end = () => {
    setLiveSessionUuid("");
    if (initialState) {
      fetch(`/api/v2/conferences/${initialState.conferenceUuid}/live`, {
        headers: postHeader(),
        method: "DELETE"
      });
    }
  };
  return { liveSessionUuid, start, end };
}

export let LiveSession = createContainer(useLiveSession);
