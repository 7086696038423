import React, { useState, useEffect } from "react";
import style from "./Comment.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { ja } from "date-fns/locale";
import { Comment } from "../@type/Comment";
import {
  faSquare,
  faCheckSquare,
  faThumbsUp
} from "@fortawesome/pro-light-svg-icons";
import { authoricationPostHeader } from "../helpers/FetchHelper";
import { AuthContainer } from "../context/auth";
import { Tag } from "antd";
import { TweetTemplate, TwitterCredentials } from "../@type/Conference";
import { TweetButton } from "./twitter/TweetButton";
import { TweetType } from "./twitter/TweetModal";

interface CommentItemProps extends Comment {
  tweetTemplate: TweetTemplate;
  twitterCredentials: TwitterCredentials;
}
export const CommentItem: React.FC<CommentItemProps> = props => {
  const [approve, setApprove] = useState(props.approve);
  useEffect(() => setApprove(props.approve), [props.approve]);
  const auth = AuthContainer.useContainer();
  return (
    <div className={style.commentContainer}>
      <ApproveCheckbox
        className={style.commentApprove}
        check={approve}
        onChange={approve => {
          fetch(`/api/v2/comments/${props.uuid}/approve`, {
            headers: authoricationPostHeader(auth.authToken),
            method: approve ? "POST" : "DELETE"
          });
          setApprove(approve);
        }}
      />
      <div className={style.commentContent}>
        <div className={style.commentHeader}>
          <div className={style.commentAuthor}>{props.author}</div>
          <div className={style.commentDateTime}>
            {formatDistanceToNow(fromUnixTime(props.time), { locale: ja })}前
          </div>
          {approve ? (
            <>
              <Tag color="green" className={style.tag}>
                承認
              </Tag>
              <TweetButton
                twitterCredentials={props.twitterCredentials}
                tweetTemplate={props.tweetTemplate}
                uuid={props.uuid}
                body={props.body}
                tweetType={TweetType.comment}
              />
            </>
          ) : (
            <Tag className={style.tag}>未承認</Tag>
          )}
        </div>
        <div className={style.commentBody}>{props.body}</div>
      </div>
      <LikeButton className={style.commentLike} isShow={approve} {...props} />
    </div>
  );
};

interface Checkbox {
  check: boolean;
  className: string;
  onChange: (checked: boolean) => void;
}
const ApproveCheckbox: React.FC<Checkbox> = props => (
  <div
    className={`${props.className} ${props.check && style.checked}`}
    onClick={() => props.onChange(!props.check)}
  >
    <div className={style.icon}>
      {props.check ? (
        <FontAwesomeIcon icon={faCheckSquare} />
      ) : (
        <FontAwesomeIcon icon={faSquare} />
      )}
    </div>
  </div>
);

interface LikeButtonProps extends Comment {
  className: string;
  isShow: boolean;
}
const LikeButton: React.FC<LikeButtonProps> = props => {
  const [likesCount, setLikesCount] = useState(props.likesCount);
  const [liked, setLiked] = useState(props.liked);
  useEffect(() => {
    setLikesCount(props.likesCount);
    setLiked(props.liked);
  }, [props.liked, props.likesCount]);
  const auth = AuthContainer.useContainer();
  return (
    <div
      className={`${props.className} ${liked && style.liked}`}
      onClick={() => {
        if (liked) {
          setLikesCount(likesCount - 1);
          setLiked(false);
        } else {
          setLikesCount(likesCount + 1);
          setLiked(true);
        }
        fetch(`/api/v2/comments/${props.uuid}/like`, {
          headers: authoricationPostHeader(auth.authToken),
          method: liked ? "DELETE" : "POST"
        });
      }}
    >
      {props.isShow && (
        <>
          <FontAwesomeIcon icon={faThumbsUp} className={style.likeIcon} />
          <span className={style.likesCount}>{likesCount}</span>
        </>
      )}
    </div>
  );
};
