import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Menu, Table, Tag } from "antd";
import { useParams } from "react-router";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop, faMobile } from "@fortawesome/pro-light-svg-icons";
import { useFetchNoAuth as useFetch } from "../hooks/Fetch";
import { Timetable } from "../components/Timetable";
import style from "./Live.module.scss";

interface SessionDateDatum {
  beginDate: string;
}
type SessionDates = SessionDateDatum[];
function isSessionDates(arg: any): arg is SessionDates {
  return (
    arg instanceof Array &&
    arg.every(mayBeSessionDatum => mayBeSessionDatum.beginDate !== undefined)
  );
}

export const Live: React.FC = () => {
  const { conferenceGroupId } = useParams<{ conferenceGroupId: string }>();
  const { fetching, fetchFulfilled, responseJson: sessionDates } = useFetch(
    `/api/v2/conference_groups/${conferenceGroupId}/session_dates`
  );
  const [showDate, setShowDate] = useState("");
  const [groupedConferenceHeight, setGroupedConferenceHeight] = useState(0);
  const menu = useRef<HTMLDivElement>({} as HTMLDivElement);
  const { current } = menu;
  useLayoutEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    if (body instanceof HTMLBodyElement && current instanceof HTMLDivElement) {
      setGroupedConferenceHeight(
        body.getBoundingClientRect().height -
          current.getBoundingClientRect().height
      );
    }
  }, [current]);
  useEffect(() => {
    if (isSessionDates(sessionDates)) {
      const today = format(new Date(), "yyyy-MM-dd");
      const sessionDate = sessionDates.find(
        sessionDate => sessionDate.beginDate === today
      );
      if (sessionDate) {
        setShowDate(sessionDate.beginDate);
      } else {
        setShowDate(sessionDates[0].beginDate);
      }
    }
  }, [sessionDates]);
  return (
    <div style={{ backgroundColor: "#e6e6e6" }}>
      {fetching && <div>読み込み中...</div>}
      {fetchFulfilled && isSessionDates(sessionDates) && (
        <>
          <div ref={menu}>
            <Menu mode="horizontal" selectedKeys={[showDate]}>
              {sessionDates.map(sessionDate => (
                <Menu.Item
                  key={sessionDate.beginDate}
                  onClick={event => setShowDate(event.key)}
                >
                  {sessionDate.beginDate}
                </Menu.Item>
              ))}
            </Menu>
          </div>
          <GroupedConferences
            showDate={showDate}
            conferenceGroupId={conferenceGroupId}
            height={groupedConferenceHeight}
          />
        </>
      )}
    </div>
  );
};

interface GroupedConferencesProps {
  showDate: string;
  conferenceGroupId: string;
  height: number;
}

enum TrackStatus {
  live = "live",
  question_box = "question_box",
  offline = "offline"
}
interface Conference {
  uuid: string;
  name: string;
  operatorKeycode: string;
  userKeycode: string;
  userKeycodeHash: string;
  trackStatus: TrackStatus;
  activeTrackName: string;
  approvingComments: boolean;
  liveAudiencesCount: number;
  loginAudiencesCount: number;
  activeLoginAudiencesCount: number;
  commentsCount: number;
}
function isConference(arg: any): arg is Conference {
  return (
    arg.uuid !== undefined &&
    arg.name !== undefined &&
    arg.operatorKeycode !== undefined &&
    arg.userKeycode !== undefined &&
    arg.userKeycodeHash !== undefined &&
    arg.trackStatus !== undefined &&
    arg.activeTrackName !== undefined &&
    arg.approvingComments !== undefined &&
    arg.liveAudiencesCount !== undefined &&
    arg.loginAudiencesCount !== undefined &&
    arg.activeLoginAudiencesCount !== undefined &&
    arg.commentsCount !== undefined
  );
}
function isConferences(arg: any): arg is Conference[] {
  return (
    arg instanceof Array &&
    arg.every(mayBeConference => isConference(mayBeConference))
  );
}

const TrackStatusView: React.FC<{ conference: Conference }> = ({
  conference
}) => {
  if (conference.trackStatus === TrackStatus.live) {
    return (
      <Tag color="blue" style={{ marginLeft: 4 }}>
        百人会議
      </Tag>
    );
  }
  if (conference.trackStatus === TrackStatus.question_box) {
    return (
      <Tag color="green" style={{ marginLeft: 4 }}>
        質問箱
      </Tag>
    );
  }
  if (conference.trackStatus === TrackStatus.offline) {
    return <Tag>オフライン</Tag>;
  }
  return <div>{JSON.stringify(conference.trackStatus)}</div>;
};

const GroupedConferences: React.FC<GroupedConferencesProps> = ({
  showDate,
  conferenceGroupId,
  height
}) => {
  const {
    primaryFetching,
    primaryFetchFulfilled,
    responseJson: conferences
  } = useFetch(`/api/v2/conference_groups/${conferenceGroupId}`, {
    autoReload: true
  });
  return (
    <div style={{ backgroundColor: "white" }}>
      {primaryFetching && <div>読み込み中</div>}
      {primaryFetchFulfilled && isConferences(conferences) && (
        <Table
          dataSource={conferences}
          expandedRowRender={record => (
            <Timetable conferenceUuid={record.uuid} showDate={showDate} />
          )}
          defaultExpandAllRows={true}
          pagination={false}
          scroll={{ y: height - 70 }}
          rowClassName={() => style.conferenceInformationRecord}
        >
          <Table.Column title="会議名" dataIndex="name" ellipsis={true} />
          <Table.Column
            title="セッション"
            dataIndex="activeTrackName"
            ellipsis={true}
          />
          <Table.Column
            title="稼働モード"
            dataIndex=""
            ellipsis={true}
            render={(_, record) =>
              isConference(record) && <TrackStatusView conference={record} />
            }
          />
          <Table.Column
            title="コメントの表示"
            dataIndex="approvingComments"
            ellipsis={true}
            render={(approvingComments: boolean) =>
              approvingComments ? (
                <Tag color="purple">承認制</Tag>
              ) : (
                <Tag color="gold">自動承認</Tag>
              )
            }
          />
          <Table.Column title="ユーザー数" dataIndex="loginAudiencesCount" />
          <Table.Column
            title="ONのユーザー数"
            dataIndex="activeLoginAudiencesCount"
            ellipsis={true}
          />
          <Table.Column
            title="ONLINE数"
            dataIndex="liveAudiencesCount"
            ellipsis={true}
          />
          <Table.Column
            title="コメント数"
            dataIndex="commentsCount"
            ellipsis={true}
          />

          <Table.Column
            title=""
            key="action"
            render={(_, record) =>
              isConference(record) && (
                <>
                  <a
                    href={`https://www.kaigi100.com/pc?ok=${record.operatorKeycode}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faDesktop} />
                  </a>
                  <span style={{ margin: "0 4px" }}>/</span>
                  <a
                    href={`https://www.kaigi100.com/sp/conferences/${record.uuid}/qr/${record.userKeycodeHash}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faMobile} />
                  </a>
                </>
              )
            }
          />
        </Table>
      )}
    </div>
  );
};
