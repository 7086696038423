import React, { useRef, useLayoutEffect, useState } from "react";
import Color from "color";
import style from "./Bar.module.scss";

interface Props {
  label: string;
  value: number;
  ratio: number;
  width: string;
  fillColor: string;
  baseSize: string;
  labelSize: string;
  showValue: boolean;
}
export const Bar: React.FC<Props> = ({
  label,
  value,
  ratio,
  width,
  fillColor,
  baseSize,
  labelSize,
  showValue: optShowValue
}) => {
  const barRef = useRef({} as HTMLInputElement);
  const valueRef = useRef({} as HTMLInputElement);
  const [showValue, setShowValue] = useState(optShowValue);
  useLayoutEffect(() => {
    const barReact = barRef.current.getBoundingClientRect();
    const valueRect = valueRef.current.getBoundingClientRect();
    setShowValue(optShowValue && barReact.width > valueRect.width);
  }, [value, optShowValue]);
  return (
    <div className={style.datum} key={label} style={{ fontSize: baseSize }}>
      <div className={style.label} style={{ fontSize: labelSize }}>
        {label}
      </div>
      <div className={style.value}>
        <div
          ref={barRef}
          className={style.bar}
          style={{
            width: width,
            backgroundColor: fillColor
          }}
        >
          <span className={style.number} ref={valueRef}>
            {showValue && value}
          </span>
        </div>
        <span className={style.ratio} style={{ color: fillColor, filter: `drop-shadow(2px 2px 1px ${Color(fillColor).lighten(0.8)})` }}>
          {ratio}%
        </span>
      </div>
    </div>
  );
};
