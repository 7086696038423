import { createContainer } from "unstated-next";
import { useState, useEffect } from "react";
import { authorizationHeader } from "../helpers/FetchHelper";

enum AuthStatus {
  PENDING,
  AUTHENTICATED,
  NOT_AUTHENTICATED,
  INIT
}
interface JWTState {
  auth_token: string;
}

const useAuth = (initialState = "") => {
  const [authToken, setAuthToken] = useState(initialState);
  const [authStatus, setAuthStatus] = useState<AuthStatus>(AuthStatus.INIT);
  useEffect(() => {
    async function start() {
      if (authStatus !== AuthStatus.INIT) {
        return;
      }
      setAuthStatus(AuthStatus.PENDING);
      if (authToken === "") {
        setAuthStatus(AuthStatus.NOT_AUTHENTICATED);
        return;
      }
      const response = await fetch("/api/v2/authentications", {
        headers: authorizationHeader(authToken)
      });
      if (response.status !== 200) {
        setAuthStatus(AuthStatus.NOT_AUTHENTICATED);
        setAuthToken("");
        return;
      }
      setAuthStatus(AuthStatus.AUTHENTICATED);
    }
    start();
  }, [authToken, authStatus]);
  const authentication = async (
    loginId: string,
    password: string
  ): Promise<Response> => {
    const response = await fetch("/api/v2/authentications", {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        email: loginId,
        password: password
      })
    });
    if (response.status !== 200) {
      setAuthStatus(AuthStatus.NOT_AUTHENTICATED);
      setAuthToken("");
      return response;
    }
    const json = (await response.json()) as JWTState;
    setAuthToken(json.auth_token);
    setAuthStatus(AuthStatus.AUTHENTICATED);
    localStorage.setItem("jwt", json.auth_token);
    return response;
  };
  const authenticated = () => authStatus === AuthStatus.AUTHENTICATED;
  const notAuthenticated = () => authStatus === AuthStatus.NOT_AUTHENTICATED;
  const signOut = () => {
    setAuthStatus(AuthStatus.NOT_AUTHENTICATED);
    setAuthToken("");
  };
  return {
    authToken,
    setAuthToken,
    authenticated,
    notAuthenticated,
    authentication,
    signOut
  };
};

export const AuthContainer = createContainer(useAuth);

// export class AuthContainer extends Container<AuthState> {
//   state = {
//     authToken: ""
//   };

//   setAuthToken(authToken: string) {
//     this.setState({ authToken });
//   }

//   authenticated = (): boolean => {
//     return this.state.authToken !== "";
//   };
// }
