import React, { useState, useEffect } from "react";
import style from "./Schedule.module.scss";
import { format, fromUnixTime } from "date-fns";
import { Tag } from "antd";
import { Session, LiveSession } from "../Timetable";
import { Action } from "./Action";

// ref. https://qiita.com/niusounds/items/c4af702b06582590c82e
const createScale = (
  rangeA: number[],
  rangeB: number[]
): ((x: number) => number) => {
  const [aMin, aMax] = rangeA;
  const [bMin, bMax] = rangeB;
  return (x: number) => bMin + ((bMax - bMin) * (x - aMin)) / (aMax - aMin);
};
export const Schedule: React.FC<Session> = props => {
  const beginTime = parseInt(format(fromUnixTime(props.begin), "kmm"));
  const endTime = parseInt(format(fromUnixTime(props.end), "kmm"));
  const scale = createScale([700, 2200], [0, 100]);
  const liveSession = LiveSession.useContainer();
  const [live, setLive] = useState(props.live);
  const [kind, setKind] = useState(props.kind);
  useEffect(() => {
    setLive(liveSession.liveSessionUuid === props.uuid);
  }, [liveSession.liveSessionUuid, props.uuid]);
  return (
    <div
      className={`${style.schedule} ${style[kind]}`}
      style={{
        left: `${scale(beginTime)}%`,
        width: `${scale(endTime) - scale(beginTime)}%`
      }}
    >
      <span className={style.scheduleEvent}>
        {live && (
          <Tag
            color="red"
            style={{
              marginLeft: 0,
              marginRight: 5,
              padding: "2px 5px",
              lineHeight: "1em",
              fontSize: 11
            }}
          >
            ON
          </Tag>
        )}
        {props.name}
      </span>
      <Action
        {...props}
        kind={kind}
        onKindChange={kind => setKind(kind)}
      />
    </div>
  );
};
